/**
 * Cohelion Demo Pooling
 * API documentation for Cohelion Demo Pooling
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';

import { DashboardButtonResult }                                     from '../model/dashboardButtonResult';
import { DashboardResponse }                                     from '../model/dashboardResponse';
import { Report }                                     from '../model/report';
import { ReportCategory }                                     from '../model/reportCategory';
import { ReportCategoryItem }                                     from '../model/reportCategoryItem';
import { ReportFormDefinition }                                     from '../model/reportFormDefinition';
import { ResultMessage }                                     from '../model/resultMessage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReportingService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all reports including form actions, form-definition, etc. required for report selection info
     * Get all reports including form actions, form-definition, etc. required for report selection info
     * @param categoryId ID of a report category
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsGet(categoryId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<ReportCategoryItem>>;
    public reportingCategoriesCategoryIdReportsGet(categoryId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReportCategoryItem>>>;
    public reportingCategoriesCategoryIdReportsGet(categoryId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReportCategoryItem>>>;
    public reportingCategoriesCategoryIdReportsGet(categoryId: number, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Array<ReportCategoryItem>>(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get report Excel output.
     * @param categoryId ID of a report category
     * @param reportId ID of a report
     * @param formDefinitionSelection 
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsReportIdExcelPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public reportingCategoriesCategoryIdReportsReportIdExcelPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public reportingCategoriesCategoryIdReportsReportIdExcelPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public reportingCategoriesCategoryIdReportsReportIdExcelPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdExcelPost.');
        }
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdExcelPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel;application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (formDefinitionSelection !== undefined) {
            formParams = formParams.append('formDefinitionSelection', <any>formDefinitionSelection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports/${encodeURIComponent(String(reportId))}/excel`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get report html output.
     * Get report html output.
     * @param categoryId ID of a report category
     * @param reportId ID of a report
     * @param formDefinitionSelection 
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost(categoryId: number, reportId: number, formDefinitionSelection: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ReportFormDefinition>;
    public reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost(categoryId: number, reportId: number, formDefinitionSelection: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReportFormDefinition>>;
    public reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost(categoryId: number, reportId: number, formDefinitionSelection: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReportFormDefinition>>;
    public reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost(categoryId: number, reportId: number, formDefinitionSelection: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost.');
        }
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost.');
        }
        if (formDefinitionSelection === null || formDefinitionSelection === undefined) {
            throw new Error('Required parameter formDefinitionSelection was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdFormDefinitionsPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (formDefinitionSelection !== undefined) {
            formParams = formParams.append('formDefinitionSelection', <any>formDefinitionSelection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ReportFormDefinition>(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports/${encodeURIComponent(String(reportId))}/form-definitions`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get form actions, form-definition, etc. required for report selection info
     * Get form actions, form-definition, etc. required for report selection info
     * @param categoryId ID of a report category
     * @param reportId ID of a report
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsReportIdGet(categoryId: number, reportId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Report>;
    public reportingCategoriesCategoryIdReportsReportIdGet(categoryId: number, reportId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Report>>;
    public reportingCategoriesCategoryIdReportsReportIdGet(categoryId: number, reportId: number, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Report>>;
    public reportingCategoriesCategoryIdReportsReportIdGet(categoryId: number, reportId: number, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdGet.');
        }
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Report>(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports/${encodeURIComponent(String(reportId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get report html output.
     * Get report html output.
     * @param categoryId ID of a report category
     * @param reportId ID of a report
     * @param formDefinitionSelection 
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsReportIdHtmlPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public reportingCategoriesCategoryIdReportsReportIdHtmlPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public reportingCategoriesCategoryIdReportsReportIdHtmlPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public reportingCategoriesCategoryIdReportsReportIdHtmlPost(categoryId: number, reportId: number, formDefinitionSelection?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdHtmlPost.');
        }
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdHtmlPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (formDefinitionSelection !== undefined) {
            formParams = formParams.append('formDefinitionSelection', <any>formDefinitionSelection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<string>(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports/${encodeURIComponent(String(reportId))}/html`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get report PDF output.
     * @param categoryId ID of a report category
     * @param reportId ID of a report
     * @param formDefinitionSelection 
     * @param asHtml Flag indicating to return html instead of PDF
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesCategoryIdReportsReportIdPdfPost(categoryId: number, reportId: number, formDefinitionSelection?: string, asHtml?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public reportingCategoriesCategoryIdReportsReportIdPdfPost(categoryId: number, reportId: number, formDefinitionSelection?: string, asHtml?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public reportingCategoriesCategoryIdReportsReportIdPdfPost(categoryId: number, reportId: number, formDefinitionSelection?: string, asHtml?: boolean, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public reportingCategoriesCategoryIdReportsReportIdPdfPost(categoryId: number, reportId: number, formDefinitionSelection?: string, asHtml?: boolean, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdPdfPost.');
        }
        if (reportId === null || reportId === undefined) {
            throw new Error('Required parameter reportId was null or undefined when calling reportingCategoriesCategoryIdReportsReportIdPdfPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (asHtml !== undefined) {
            queryParameters = queryParameters.set('AsHtml', <any>asHtml);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (formDefinitionSelection !== undefined) {
            formParams = formParams.append('formDefinitionSelection', <any>formDefinitionSelection) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post(`${this.basePath}/reporting/categories/${encodeURIComponent(String(categoryId))}/reports/${encodeURIComponent(String(reportId))}/pdf`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Returns all report categories for account
     * Returns all report categories for account
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Array<ReportCategory>>;
    public reportingCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReportCategory>>>;
    public reportingCategoriesGet(csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReportCategory>>>;
    public reportingCategoriesGet(csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.get<Array<ReportCategory>>(`${this.basePath}/reporting/categories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get dashboard data
     * @param name Dashboard name
     * @param panel Panel name
     * @param params Params object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingDashboardNameExportPanelPost(name: string, panel: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public reportingDashboardNameExportPanelPost(name: string, panel: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public reportingDashboardNameExportPanelPost(name: string, panel: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public reportingDashboardNameExportPanelPost(name: string, panel: string, params?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling reportingDashboardNameExportPanelPost.');
        }
        if (panel === null || panel === undefined) {
            throw new Error('Required parameter panel was null or undefined when calling reportingDashboardNameExportPanelPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post(`${this.basePath}/reporting/dashboard/${encodeURIComponent(String(name))}/export/${encodeURIComponent(String(panel))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Trigger action button , returns DashboardButtonResult
     * Trigger action button , returns DashboardButtonResult
     * @param name Dashboard name
     * @param panel Panel name
     * @param buttonName Button name
     * @param params Params object for grid
     * @param payload Payload object from button
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingDashboardNamePanelButtonButtonNamePost(name: string, panel: string, buttonName: string, params?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<DashboardButtonResult>;
    public reportingDashboardNamePanelButtonButtonNamePost(name: string, panel: string, buttonName: string, params?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardButtonResult>>;
    public reportingDashboardNamePanelButtonButtonNamePost(name: string, panel: string, buttonName: string, params?: string, payload?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardButtonResult>>;
    public reportingDashboardNamePanelButtonButtonNamePost(name: string, panel: string, buttonName: string, params?: string, payload?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling reportingDashboardNamePanelButtonButtonNamePost.');
        }
        if (panel === null || panel === undefined) {
            throw new Error('Required parameter panel was null or undefined when calling reportingDashboardNamePanelButtonButtonNamePost.');
        }
        if (buttonName === null || buttonName === undefined) {
            throw new Error('Required parameter buttonName was null or undefined when calling reportingDashboardNamePanelButtonButtonNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (payload !== undefined) {
            formParams = formParams.append('payload', <any>payload) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<DashboardButtonResult>(`${this.basePath}/reporting/dashboard/${encodeURIComponent(String(name))}/${encodeURIComponent(String(panel))}/button/${encodeURIComponent(String(buttonName))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get dashboard data
     * @param name Dashboard name
     * @param params Params object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingDashboardNamePost(name: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<DashboardResponse>;
    public reportingDashboardNamePost(name: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DashboardResponse>>;
    public reportingDashboardNamePost(name: string, params?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DashboardResponse>>;
    public reportingDashboardNamePost(name: string, params?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling reportingDashboardNamePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<DashboardResponse>(`${this.basePath}/reporting/dashboard/${encodeURIComponent(String(name))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * 
     * Get dashboard data
     * @param name Dashboard name
     * @param panel Panel name
     * @param selection Params object as JSON string
     * @param data Params object as JSON string
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingDashboardNameSavePanelPost(name: string, panel: string, selection?: string, data?: { [key: string]: string; }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<ResultMessage>;
    public reportingDashboardNameSavePanelPost(name: string, panel: string, selection?: string, data?: { [key: string]: string; }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultMessage>>;
    public reportingDashboardNameSavePanelPost(name: string, panel: string, selection?: string, data?: { [key: string]: string; }, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultMessage>>;
    public reportingDashboardNameSavePanelPost(name: string, panel: string, selection?: string, data?: { [key: string]: string; }, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling reportingDashboardNameSavePanelPost.');
        }
        if (panel === null || panel === undefined) {
            throw new Error('Required parameter panel was null or undefined when calling reportingDashboardNameSavePanelPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (selection !== undefined) {
            formParams = formParams.append('selection', <any>selection) || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<ResultMessage>(`${this.basePath}/reporting/dashboard/${encodeURIComponent(String(name))}/save/${encodeURIComponent(String(panel))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
